import {
  APITypes,
  CoreAPITypes,
  SphereDashboardAPITypes,
} from "@stellar/api-logic";
import { RootState } from "@store/store-helper";
import {
  SdbCompaniesAdapter,
  SdbCompanyState,
} from "@store/sdb-company/sdb-company-slice";
import { OpenProjectTarget, SdbCompany } from "@custom-types/sdb-company-types";
import { EntityId, createSelector } from "@reduxjs/toolkit";
import { SPHERE_VIEWER_COMPANY_FEATURES } from "@src/constants/sdb-company-constants";
import { SPHERE_LEGACY_MIGRATED_TAG } from "@utils/sdb-company-utils";
import { runtimeConfig } from "@src/runtime-config";
import { addNumberedEnv } from "@utils/url-utils";

/**
 * Returns the ID of the selected SdbCompany
 */
export const selectedSdbCompanyIdSelector: (state: RootState) => string | null =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.sdbCompany.selectedSdbCompanyId;
    }
  );

/**
 * Checks if the given id is the same as the currently selected sdbCompany
 */
export function isSdbCompanySelectedSelector(
  sdbCompanyId: string
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return selectedSdbCompanyIdSelector(state) === sdbCompanyId;
    }
  );
}

/**
 * Returns the current selected SdbCompany
 */
export const selectedSdbCompanySelector: (
  state: RootState
) => SdbCompany | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const sdbCompanyId = selectedSdbCompanyIdSelector(state);
    if (!sdbCompanyId) {
      return null;
    }
    // Find the specific sdb-company using the id.
    return (
      SdbCompaniesAdapter.getSelectors().selectById(
        state.sdbCompany,
        sdbCompanyId
      ) ?? null
    );
  }
);

/**
 * Returns whether this company should only show actions regarding
 * Sphere XG and ignore things only relevant for HoloBuilder customers
 */
export const isSphereXGExclusiveWorkspaceSelector: (
  state: RootState
) => boolean = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const selectedCompany = selectedSdbCompanySelector(state);
    if (!selectedCompany) {
      // If no company is yet selected, we assume it is a SphereXG only company
      // to avoid showing HoloBuilder by default.
      return true;
    }
    return selectedCompany.isSphereXGExclusive;
  }
);

/**
 * Returns the selected sdbCompany name
 */
export const selectedSdbCompanyNameSelector: (
  state: RootState
) => string | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return selectedSdbCompanySelector(state)?.name ?? null;
  }
);

/**
 * Returns all sdbCompany of the user
 */
export const sdbCompaniesSelector: (state: RootState) => SdbCompany[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return (
        SdbCompaniesAdapter.getSelectors()
          .selectAll(state.sdbCompany)
          // Filters out migrated workspaces if the flag is enabled.
          // Do not do this on filteredSdbCompaniesSelector, so that the switch workspaces menu
          // can benefit from this filter.
          .filter((company) => {
            if (
              state.ui.isHideMigratedWorkspacesEnabled &&
              company.tags.includes(SPHERE_LEGACY_MIGRATED_TAG)
            ) {
              return false;
            }
            return true;
          })
          .map((company) => {
            let url = company.url;
            if (
              company.type === CoreAPITypes.EWorkspaceType.company &&
              runtimeConfig.isNumberedEnv &&
              runtimeConfig.numberedEnv
            ) {
              url = addNumberedEnv(url, runtimeConfig.numberedEnv);
            }
            return {
              ...company,
              url,
            };
          })
      );
    }
  );

/**
 * Returns all sdbCompany of the user, filtered by the search text
 */
export const filteredSdbCompaniesSelector: (state: RootState) => SdbCompany[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const search = state.ui.search.searchText.toLowerCase();
      return sdbCompaniesSelector(state).filter((company) =>
        company.name.toLowerCase().includes(search)
      );
    }
  );

/**
 * Returns all Ids of sdbCompany of the user
 */
export const sdbCompanyIdsSelector: (state: RootState) => EntityId[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return SdbCompaniesAdapter.getSelectors().selectIds(state.sdbCompany);
    }
  );

/**
 * Returns all companies of the user.
 */
export const companiesSelector: (state: RootState) => SdbCompany[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return sdbCompaniesSelector(state).filter(
        ({ type }) => type === CoreAPITypes.EWorkspaceType.company
      );
    }
  );

/**
 * Returns all admin workspaces of the user.
 */
export const adminWorkspacesSelector: (state: RootState) => SdbCompany[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return sdbCompaniesSelector(state).filter(
        ({ type }) => type === CoreAPITypes.EWorkspaceType.admin
      );
    }
  );

/**
 * Returns all company features for the selected company.
 */
export const selectedCompanyFeaturesSelector: (
  state: RootState
) => CoreAPITypes.IFeatureStateResponse[] | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.sdbCompany.selectedCompanyFeatures;
  }
);

/**
 * Returns the fetching properties of the sdb-company slice.
 */
export const fetchingSdbCompanyFlagsSelector: (
  state: RootState
) => SdbCompanyState["fetching"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.sdbCompany.fetching;
  }
);

/**
 * Returns true if any fetching regarding company-slice is true.
 */
export const isFetchingSdbCompanySelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const {
        isFetchingSelectedCompanyContext,
        isFetchingSelectedCompanyFeatures,
        isFetchingSdbCompanies,
        isFetchingCompanyBrandingSetting,
        isFetchingCompanyCommunicationSettings,
      } = state.sdbCompany.fetching;

      return (
        isFetchingSelectedCompanyContext ||
        isFetchingSelectedCompanyFeatures ||
        isFetchingCompanyBrandingSetting ||
        isFetchingSdbCompanies ||
        isFetchingCompanyCommunicationSettings
      );
    }
  );

/**
 * Returns company context for the selected company.
 */
export const selectedCompanyContextSelector: (
  state: RootState
) => APITypes.ICompanyContext | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.sdbCompany.selectedCompanyContext;
  }
);

/**
 * Returns branding settings for the selected company.
 */
export const companyBrandingSettingsSelector: (
  state: RootState
) => APITypes.BrandingSettings | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.sdbCompany.brandingSettings;
  }
);

/** Get company details by providing the companyId */
export function getCompanyByIdSelector(
  companyId: APITypes.CompanyId
): (state: RootState) => SdbCompany | null {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return (
        SdbCompaniesAdapter.getSelectors().selectById(
          state.sdbCompany,
          companyId
        ) ?? null
      );
    }
  );
}

/**
 * Returns communication settings for the selected company.
 */
export const companyCommunicationSettingsSelector: (
  state: RootState
) => SphereDashboardAPITypes.CompanyCommunicationSettings | null =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.sdbCompany.communicationSettings;
    }
  );

export const companySettingsSelector: (
  state: RootState
) => Record<
  SphereDashboardAPITypes.CompanySetting["identifier"],
  SphereDashboardAPITypes.CompanySetting["value"]
> = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.sdbCompany.companySettings;
  }
);

/**
 * Returns the default open project for the selected company.
 */
export const defaultOpenProjectTargetSelector: (
  state: RootState
) => OpenProjectTarget = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const isSphereXGExclusiveWorkspace =
      isSphereXGExclusiveWorkspaceSelector(state);
    if (isSphereXGExclusiveWorkspace) {
      // For Sphere XG workspaces, we do not care about the company settings,
      // we always use the Sphere Viewer as the default open target.
      return OpenProjectTarget.sphereViewer;
    }

    // Get the company open target setting, if available, use it to set the open target
    const isSphereViewerDefault =
      companyCommunicationSettingsSelector(state)?.sphereViewerIsDefault;

    if (isSphereViewerDefault !== undefined) {
      return isSphereViewerDefault
        ? OpenProjectTarget.sphereViewer
        : OpenProjectTarget.webEditor;
    }

    // If the company open target setting is not set explicitly by the user yet,
    // check if the user has specific features enabled
    const companyFeatures = selectedCompanyFeaturesSelector(state);

    if (companyFeatures !== null) {
      const hasViewerFeatures = companyFeatures.some(
        (feature) =>
          SPHERE_VIEWER_COMPANY_FEATURES.includes(feature.identifier) &&
          feature.state === "enabled"
      );

      return hasViewerFeatures
        ? OpenProjectTarget.sphereViewer
        : OpenProjectTarget.webEditor;
    }

    // If company open target setting is not available and company features are not available then set
    // WebEditor as open target
    return OpenProjectTarget.webEditor;
  }
);
